
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import RadarChart from './components/RadarChart.vue';
import KpiTable from './components/KpiTable.vue';

export default {
  components: { RadarChart, KpiTable },
  data() {
    return {
      lastTimeStamp: Date.now(),
      tab: 'All'
    };
  },
  computed: {
    ...mapState('detailedReport', ['kpiLineChart'])
  },
  mounted() {
    Vue?.prototype?.$mixpanel?.track('detailed_report', {
      referrer: document.referrer
    });
  },
  methods: {
    ...mapMutations('detailedReport', ['setSelectedKPI', 'setCompareKPI']),
    refreshPage() {
      this.lastTimeStamp = Date.now();
    }
  }
};
